.expandedGame {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  max-width: 600px;
  height: 80vh;
  background-color: black;
  z-index: 10;
  border-radius: 25px;
  overflow: hidden;
  margin: 2rem 0;
  color: white;
  padding-bottom: 2rem;
}

.expandedGameInfoDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  background-color: white;
  color: black;
}

.expandedGameBets {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  position: relative;
  box-sizing: border-box;
  background-color: #2b2b2b;
  overflow-y: auto;
  padding-bottom: 1rem;
  color: black;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.expandedGameBet {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  background-color: white;
  box-sizing: border-box;
  border-radius: 25px;
  padding: 1rem;
  margin: 1rem 0;
  gap: 1rem;
}

.expandedGameResult {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
}

.expandedGameAddBetsBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  justify-content: center;
  padding: 2rem;
  text-align: center;
}

.expandedGameAddBetsBtn button {
  width: 150px;
  height: auto;
  padding: 1rem;
  font-size: 1.2rem;
  background-color: #39ff14;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  margin-top: 2rem;
  transition: 0.1s ease-in-out;
}

.expandedGameAddBetsBtn button:hover {
  transform: scale(1.1);
}

.expandedGameAddBetsBtn button:active {
  transition: 0.05s ease-in-out;
  transform: scale(0.9);
}

.expandedGameFormDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: auto;
  padding: 1rem;
  box-sizing: border-box;
  background-color: darkcyan;
  border-radius: 10px;
  margin-top: 1rem;
  z-index: 10;
}

.expandedGameFormDiv form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  gap: 1rem;
}

.expandedGameFormDiv form input {
  width: 70px;
  height: 35px;
  font-size: 1rem;
  border-radius: 10px;
  border: none;
  text-align: center;
}

.expandedGameFormDiv form button {
  width: auto;
  height: 35px;
  font-size: 1rem;
  background-color: black;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  transition: 0.1s ease-in-out;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  gap: 1rem;
}
