.adminExpandedGame {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  background-color: lightgray;
  border-radius: 20px;
}
