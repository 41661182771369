.apMainDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #2b2b2b;
}

.apLogOrRegDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}

.apButtonDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  background-color: #e6e6dd;
}

.apHeading {
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 20px;
}

.apInnerButtonDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}

.apButton {
  background-color: gray;
  border: none;
  height: 40px;
  width: 120px;
  border-radius: 20px;
  font-size: 1rem;
  transition: ease-in-out 0.2s;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.apButton:hover {
  background-color: black;
  color: white;
  scale: 1.1;
}

.apButton:active {
  transition: ease-in-out 0.05s;
  scale: 0.9;
}

.apHighlightButton {
  background-color: #39ff14;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.apHighlightButton:hover {
  color: black;
}

.apHighlightButton:active {
  color: black;
}

.halfDivAP {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  color: #e6e6dd;
}

.halfDivAP h1 {
  font-size: 3rem;
  margin: 0;
}

.landingPageMainDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #2b2b2b;
}

.imgDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1;
}

.imgDiv img {
  width: 90%;
  height: auto;
  max-width: 450px;
}

.landingPageContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 2;
  color: #e6e6dd;
  padding-top: 20vh;
  max-width: 450px;
}

.headingDivLP {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin-bottom: 2rem;
}

.headingDivLP h1 {
  font-size: 60px;
  font-family: 'Anton', sans-serif;
  margin: 0;
  width: 90%;
  text-align: left;
}

.headingDivLP h3 {
  font-size: 20px;
  font-family: 'Anton', sans-serif;
  margin: 0;
  width: 90%;
  text-align: right;
}

.buttonDivLP {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}

.buttonDivLP button {
  transition: ease-in-out 0.1s;
  border: none;
  width: 120px;
  color: black;
}

.buttonDivLP button:hover {
  scale: 1.1;
}

.buttonDivLP button:active {
  scale: 0.9;
}

.landingPageLginButton {
  height: auto;
  border-radius: 5px;
  background-color: #39ff14;
  font-family: 'Anton', sans-serif;
  font-size: 20px;
}

.landingPageRegButton {
  height: auto;
  border-radius: 5px;
}
