.rgstMainDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: auto;
  border-radius: 20px;
  background: rgb(238, 174, 202);
  background: radial-gradient(
    circle,
    rgba(238, 174, 202, 1) 0%,
    rgba(148, 187, 233, 1) 100%
  );
  margin-top: 10vh;
  gap: 20px;
  padding: 20px;
  box-sizing: border-box;
}

.rgstMainDiv h3 {
  font-size: 1.5rem;
}

.rgstMainDiv input {
  padding: 5px;
  border-radius: 5px;
  overflow: auto;
  border: none;
  border-bottom: 2px solid black;
}
