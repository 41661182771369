.joinRequestsContainer {
  padding: 20px;
  max-width: 800px;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.requestItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: black;
  width: 100%;
}

.requestLabel {
  font-weight: bold;
}

.requestItem p {
  margin: 5px 0;
}

button {
  margin-top: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.acceptButton {
  background-color: #4caf50;
  color: white;
  margin-right: 10px;
}

.rejectButton {
  background-color: #f44336;
  color: white;
}
