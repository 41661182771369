.ergebnisEintragenDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 600px;
  padding: 1rem;
  background-color: white;
  color: black;
  border-radius: 20px;
  gap: 10px;
  height: auto;
  overflow: hidden;
}

.ergebnisEintragenContainer {
  width: 100%;
  max-height: 70vh; /* Adjust height as needed */
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.adminKontoStändeDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  padding: 0.5rem;
  box-sizing: border-box;
  background-color: #f0f0f0;
  border-radius: 10px;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

.adminErgbenisEintragen {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 95%;
  background-color: gray;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
  gap: 10px;
}

.settingsAddIconDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  background-color: white;
  cursor: pointer;
}

.ergebnisEintragenForm {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem;
  background-color: #e0e0e0;
  border-radius: 10px;
  margin-top: 0.5rem;
}

.ergebnisEintragenForm form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
}
