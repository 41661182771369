.adminHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  padding-left: 1rem;
  padding-right: 2rem;
  background-color: #2b2b2b;
  color: white;
}

.adminHeader button {
  border: none;
  background: none;
  color: white;
}

.adminContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  box-sizing: border-box;
  background-color: black;
  padding-top: 2rem;
  padding-bottom: 6rem;
  color: white;
}

.adminUserComp {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.adminUserComp input {
  width: 300px;
  text-align: center;
  height: 40px;
  border-radius: 20px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #39ff14;
  color: black;
  font-size: 18px;
}

.adminUserData {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  color: black;
  overflow: auto;
  padding-bottom: 10vh;
}

.adminUserDataInnerDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  background-color: #e6e6dd;
  border-radius: 20px;
  padding: 0 0.5rem;
  position: relative;
}

.adminUserDataInnerDiv p {
  flex: 1;
  margin: 0 0.5rem;
}

.adminExpanded {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  border-radius: 20px;
  overflow: hidden;
}

.adminNotExpanded {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 20px;
}

.adminDetails form button {
  width: 100px;
  height: 30px;
  border-radius: 15px;
  border: none;
  background-color: black;
  color: white;
}

.adminDetails form input {
  height: 30px;
  width: 150px;
  font-size: 14px;
  margin: 1rem;
  background-color: white;
  background: none;
}

.deleteBtn {
  background-color: red;
  height: 30px;
  border-radius: 15px;
  border: none;
  padding: 0 10px;
  color: white;
}

.adminTransaktionAufladen {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: green;
  border-radius: 20px;
  height: 50px;
  justify-content: space-around;
  align-items: center;
  gap: 2rem;
}

.adminTransaktionDecrease {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: rgb(255, 75, 75);
  border-radius: 20px;
  height: 50px;
  justify-content: space-around;
  align-items: center;
  gap: 2rem;
}

.adminDetails {
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  margin-top: 1rem;
  padding-bottom: 1rem;
}

.adminMainDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  overflow: auto;
}

.expandIcon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  right: 0.5rem;
}

.adminHeadingDiv h1 {
  margin: 0;
  margin-bottom: 2rem;
}
