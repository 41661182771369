:root {
  --vh: 1vh;
}

.startMainDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100); /* Höhe mit --vh Variable */
}

.startHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 8vh;
  padding: 20px;
  box-sizing: border-box;
  background-color: black;
  color: white;
}

.startHeader h1 {
  font-size: 1.4rem;
  font-weight: 400;
  margin: 0;
}

.startHeader button {
  border: none;
  background: none;
}

.startHeader button:hover {
  scale: 1.1;
}

.startHeader button:active {
  transition: 0.05s ease-in-out;
  scale: 0.9;
}

.startContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: calc(100vh - 8vh); /* Höhe minus Header */
  gap: 20px;
  overflow-y: auto;
  box-sizing: border-box;
  background-color: black;
  padding-bottom: 12vh; /* Platz für die Navbar */
}

.overflowAutoStart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.startInfoDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 25vh;
  background-color: black;
}

.startInnerInfoDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 80%;
  background-color: #e6e6dd;
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
}

.startInnerInfoDiv h1 {
  font-family: "Roboto";
  font-size: 3rem;
  margin: 0;
}

.start80Div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  background-color: #2b2b2b;
  border-radius: 20px;
  box-sizing: border-box;
  overflow: hidden;
}

.startSubHeadingDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  background-color: #eeaeca;
  box-sizing: border-box;
  padding: 10px;
}

.color1 {
  background-color: #98fb98;
}

.color2 {
  background-color: #89cff0;
}

.red {
  background-color: #ff4040;
}

.startWettverlauf {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.startSubHeadingDiv h2 {
  margin: 0;
}

.startWettenDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  gap: 10px;
  position: relative;
}

.startWetteDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: black;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}

.startMannschaftenDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 80%;
  height: auto;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
  background-color: black;
  color: white;
}

.startMannschaftenDivCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
  background-color: black;
}

.startErgebnisDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 20%;
  min-width: 20%;
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
  font-family: "Roboto";
  overflow: hidden;
  position: relative;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.startWetteInfoDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  height: auto;
  flex-wrap: wrap;
}

.startExpandButton {
  height: auto;
  width: auto;
  position: absolute;
  right: 0;
  transition: ease-in-out 0.1s;
}

.startDatumDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
  background-color: black;
  margin-right: 5px;
  font-family: "Roboto";
  font-size: 0.9rem;
  color: white;
}

.navBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 7vh;
  border-radius: 3.5vh;
  width: 90%;
  position: fixed;
  bottom: 15px;
  background-color: #2b2b2b;
  left: 50%;
  transform: translateX(-50%);
}

.navBarActiveButtonDiv {
  width: 33%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.1s ease-in-out;
  border-radius: 3.5vh;
  background-color: #39ff14;
}

.navBarButtonDiv {
  width: 33%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.1s ease-in-out;
}

.navBarIconDiv {
  transition: 0.1s ease-in-out;
  scale: 1.2;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.navBarIconDiv:hover {
  scale: 1.5;
  cursor: pointer;
}

.navBarIconDiv:active {
  scale: 0.9;
  border: none;
}

.green {
  background-color: #39ff14;
}
.red {
  background-color: #ff4040;
}

.gray {
  background-color: lightgray;
}

.orange {
  background-color: #2b2b2b;
  color: white;
}

.navBarIconDiv {
  font-size: 2rem;
  color: white;
}
