.adminAddComp {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 2rem;
  box-sizing: border-box;
}

.adminAddDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 1rem;
  background-color: rgb(61, 61, 61);
  border-radius: 20px;
}

.adminAddDiv form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  text-align: center;
  gap: 5px;
}

.adminAddDiv form label {
  margin-top: 1rem;
}

.adminAddDiv form input {
  width: 60%;
  height: 30px;
  border-radius: 10px;
  border: none;
  padding: 0.5rem;
}

.adminSubmitButton {
  margin-top: 2rem;
  background-color: #39ff14;
  transition: 0.1s ease-in-out;
  height: 40px;
  width: 200px;
  border: none;
  border-radius: 20px;
}

.adminSubmitButton:hover {
  scale: 1.1;
}

.adminSubmitButton:active {
  transition: 0.05s ease-in-out;
  scale: 0.9;
}

.successMessage {
  color: green;
  margin-top: 10px;
}

.errorMessage {
  color: red;
  margin-top: 10px;
}
