.adminDeleteGameSingleDiv {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #000;
  border-radius: 15px;
  background-color: white;
  color: black;
}

.adminGameInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.adminGameInfo p {
  margin: 0;
}

.adminGameActions {
  display: flex;
  gap: 10px;
}

.adminGameActions button {
  transition: 0.1s ease-in-out;
  height: 40px;
  width: 40px;
  border: none;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.adminGameActions button:hover {
  scale: 1.1;
}

.adminGameActions button:active {
  scale: 0.9;
  border: none;
}

.adminEditGameForm {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.adminEditInput {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.adminEditGameForm button {
  background-color: #007bff;
  transition: 0.1s ease-in-out;
  height: 40px;
  width: 40px;
  border: none;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.adminEditGameForm button:hover {
  scale: 1.1;
}

.adminEditGameForm button:active {
  scale: 0.9;
  border: none;
}
