.contentVereinAnmelden {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 50vh;
  background-color: #e6e6dd;
}

.contentVereinAnmelden form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  gap: 10px;
}

.contentVereinAnmelden input {
  padding: 5px;
  border-radius: 0px;
  overflow: auto;
  border: none;
  border-bottom: 2px solid black;
}

.error {
  border: 1px solid red !important;
}
