.Add {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: calc(100vh - 8vh); /* Platz für die Navbar */
  box-sizing: border-box;
  background-color: black;
  overflow-y: auto; /* Scrollbar hinzufügen */
  padding-bottom: 12vh; /* Platz für die Navbar einplanen */
  color: white;
}

.Add form {
  gap: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
}

.Add form input {
  width: 100px;
  text-align: center;
  height: 40px;
  border-radius: 20px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding-left: 5px;
  padding-right: 5px;
  background: rgb(119, 119, 119);
  background: linear-gradient(
    90deg,
    rgb(216, 216, 216) 0%,
    rgba(237, 237, 237, 1) 100%
  );
  font-size: 18px;
}

.Add form select {
  width: auto;
  text-align: center;
  height: 40px;
  border-radius: 20px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding-left: 5px;
  padding-right: 5px;
  background: rgb(119, 119, 119);
  background: linear-gradient(
    90deg,
    rgb(216, 216, 216) 0%,
    rgba(237, 237, 237, 1) 100%
  );
  font-size: 14px;
}

.Add form button {
  width: 200px;
  height: 50px;
  border-radius: 20px;
  border: none;
  background-color: #39ff14;
  transition: 0.1s ease-in-out;
  margin-top: 1rem;
}

.Add form button:hover {
  scale: 1.1;
  box-shadow: rgba(240, 46, 170, 0.4) -5px 5px,
    rgba(240, 46, 170, 0.3) -10px 10px, rgba(240, 46, 170, 0.2) -15px 15px,
    rgba(240, 46, 170, 0.1) -20px 20px, rgba(240, 46, 170, 0.05) -25px 25px;
}

.Add form button:active {
  scale: 0.9;
  box-shadow: rgba(240, 46, 170, 0.4) 0px 5px, rgba(240, 46, 170, 0.3) 0px 10px,
    rgba(240, 46, 170, 0.2) 0px 15px, rgba(240, 46, 170, 0.1) 0px 20px,
    rgba(240, 46, 170, 0.05) 0px 25px;
}

.form-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  gap: 10px;
}
