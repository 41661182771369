.lginMainDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 50%;
  background-color: #e6e6dd;
  padding: 2rem;
  box-sizing: border-box;
  padding-bottom: 1rem;
}

.lginMainDiv form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  gap: 20px;
}

.lginHeading {
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 20px;
}

.highlightFont {
  color: rgb(75, 75, 75);
}

.submitButton {
  width: 120px;
  height: auto !important;
  border: none;
  border-radius: 20px;
  transition: 0.2s ease-in-out;
}

.lginSubmitButton {
  border: none;
  height: auto !important;
  width: 120px;
  border-radius: 20px;
  font-size: 1rem;
  transition: ease-in-out 0.2s;
  background-color: #39ff14;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.lginSubmitButton:hover {
  scale: 1.1;
}

.lginSubmitButton:active {
  transition: 0.05s ease-in-out;
  scale: 0.9;
}

.lginMainDiv input {
  padding: 5px;
  border-radius: 0px;
  overflow: auto;
  border: none;
  border-bottom: 2px solid black;
}

.width100height100flex {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100vh;
}
