.settingsMainDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
}

.settingsContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: calc(100vh - 8vh); /* Platz für die Navbar */
  box-sizing: border-box;
  background-color: black;
  overflow-y: auto; /* Scrollbar hinzufügen */
  padding-bottom: 12vh; /* Platz für die Navbar einplanen */
  color: white;
}

.settingsContentDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.settingsTransaktionenAufgeladen {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  height: auto;
  flex-wrap: wrap;
  background-color: #c9c9c9;
  padding: 5px;
  margin-bottom: 10px; /* Abstand zwischen den Transaktionen */
  background-color: green;
  border-radius: 20px;
}

.settingsTransaktionenMinus {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  height: auto;
  flex-wrap: wrap;
  background-color: #c9c9c9;
  padding: 5px;
  margin-bottom: 10px; /* Abstand zwischen den Transaktionen */
  background-color: red;
  border-radius: 20px;
}

.fontHighlightGreen {
  text-align: center;
  width: 100%;
  font-weight: bold;
  color: #2b2b2b;
}

.infoDivSettings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  gap: 6px;
  background-color: #e6e6dd;
  color: black;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 20px;
}

.infoDivSettings p {
  text-align: center;
  width: 100%;
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100px; /* Du kannst die maximale Höhe anpassen */
  max-width: 100px; /* Du kannst die maximale Breite anpassen */
  margin: 1rem 0; /* Optional: fügt einen äußeren Abstand hinzu */
  overflow: hidden; /* Verhindert, dass das Bild über den Container hinausläuft */
  border-radius: 50%; /* Optional: fügt abgerundete Ecken hinzu, z.B. für ein rundes Logo */
  border: 2px solid #ccc; /* Optional: fügt einen Rand um das Logo hinzu */
  padding: 20px;
}

.logoContainer img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
  object-fit: contain; /* Beibehaltung des Seitenverhältnisses des Bildes */
}
