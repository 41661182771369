body {
  margin: 0;
  overflow: hidden;
  font-family: "Raleway", "Roboto", sans-serif;
}

#root {
  height: 100vh;
}

button {
  cursor: pointer;
}
